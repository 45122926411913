<template>
    <v-container class="d-flex align-center justify-center pie-chart__container">
        <vue-chart :option="graphData" class="pie-chart"></vue-chart>
    </v-container>
</template>

<script>
import ECharts from 'vue-echarts'
import { use } from 'echarts/core'

import { CanvasRenderer } from 'echarts/renderers'
import { PieChart } from 'echarts/charts'
import { GridComponent, TooltipComponent } from 'echarts/components'

use([CanvasRenderer, PieChart, GridComponent, TooltipComponent])

export default {
    name: 'SimplePieChart',
    components: {
        'vue-chart': ECharts,
    },
    props: {
        data: { required: true },
        showLabel: { type: Boolean, default: true },
        seriesName: { type: String, default: 'Velocidad lectora' }, 
    },
    computed: {
        graphData() {
            return {
                textStyle: {
                    fontFamily: 'Poppins',
                },
                tooltip: {
                    trigger: 'item',
                },
                legend: {
                    show: this.showLabel,
                    bottom: '0%',
                    left: 'center',
                },
                series: [
                    {
                        type: 'pie',
                        name: this.seriesName,
                        radius: ['40%', '70%'],
                        label: {
                            show: false,
                        },
                        data: this.data,
                    },
                ],
            }
        },
    },
}
</script>

<style lang="css" scoped>
.pie-chart__container {
    position: relative;
    width: 100%;
    height: 100%;
}
.pie-chart {
    width: 120px !important;
    height: 100px !important;
}

@media (min-width: 900px) {
    .pie-chart {
        width: 150px !important;
        height: 130px !important;
    }
}

@media (min-width: 1024px) {
    .pie-chart {
        width: 270px !important;
        height: 130px !important;
    }
}
</style>
